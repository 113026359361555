.cart-open .site-nav__icons {
    display: flex !important;
}
.custom-text-section .fast-simon-upsell {
    margin-top: -60px;
}
.hero__sidebyside-content.new-slideshow {
    max-width: 62%;
}
.review-item__body--hero p, .review-item__body p {
    font-size: 16px !important;
}
.cart-open .site-nav__close-cart {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block !important;
    opacity: 0;
}
button#hideshow {
    background: url(https://cdn.shopify.com/s/files/1/0585/9986/6575/files/search.svg?v=1661838581);
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    display:none;
  background-repeat: no-repeat !important;
  background-position: center;
}
div#content-search{
  display:none !important;
}
div#content-search.show{
  display:block !important; /* P.S: Use `!important` if missing `#content` (selector specificity). */
}
.hero__sidebyside-content.new-slideshow {
    margin: auto;
    text-align: center;
}

.hero__sidebyside-content.new-slideshow h2.h1.hero__title {
    color: #ffffff;
    font-weight: 700;
    font-family: "Nunito Sans";
    margin-bottom: 60px !important;
}
.hero__sidebyside-content h2.h1.hero__title {
    color: #ffffff;
    font-weight: 700;
    font-family: "Nunito Sans";
}

.hero__subtitle {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
    font-weight: 600;
}

.hero__sidebyside.hero__sidebyside-text--right.color-scheme-2 .hero__sidebyside-content-inner {
    max-width: 830px;
}
.index-section {
    margin: 80px 0;
}

.new-footer-pro .footer__grid-image {
    min-width: 60px;
    padding-right: 10px;
}
.footer-promotion {
    padding: 27px 0 !important;
}
.hero__link .btn {
    background: #4cc298 !important;
    border-radius: 4px;
    min-width: 220px;
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 30px;
    color: #ffffff;
}
.footer__grid-image {
    margin-bottom: 0 !important;
}
.section--divider1 {
    padding-top: 50px;
}

h2.section-header__title {
    padding-bottom: 0 !important;
}

.grid-item.new-grid1 {
    height: 227px;
}
.rte--block {
    margin-bottom: 0 !important;
}

.new-footer-pro .footer-promotion {
    border: 2px solid #e3e3e3;
    border-radius: 4px;
}
.h3.rte--block, .section-header__title, .custom-text-section .fs-recommendation-widget-title {
    text-align: center;
    font-size: 36px;
    line-height: 49px;
    font-weight: 700;
    font-family: "Nunito Sans";
    margin-bottom: 68px !important;
    color: #1D1C1C;
}
ul.site-nav.site-navigation.site-navigation--below .site-nav__item:last-child .site-nav__dropdown{
    right: 0 !important;
    left: auto;
}
.header-item--icons {
    position: relative;
}
.color-scheme-2 .klaviyo-form-WJNnKp.klaviyo-form {
    max-width: 400px;
    margin: auto;
}

.color-scheme-2 .klaviyo-form-WJNnKp.klaviyo-form button.needsclick.go3894874857.kl-private-reset-css-Xuajs1 {
    padding-left: 23px !important;
    padding-right: 23px !important;
}

.color-scheme-2 .klaviyo-form-WJNnKp.klaviyo-form input.needsclick.go610784064.kl-private-reset-css-Xuajs1 {
    border: 0 !important;
    border-right: 1px solid #e8e8e1 !important;
}

.product-single__form .add-to-cart:after {
    content: "Add to Cart";
}
.footer__section .klaviyo-form-WJNnKp.klaviyo-form {
}
span.visually-hidden {
    display: none;
}

.footer__section .klaviyo-form-WJNnKp.klaviyo-form button.needsclick.go3894874857.kl-private-reset-css-Xuajs1 {
    padding-left: 23px !important;
    padding-right: 23px !important;
    background: #0e4c89 !important;
    color: #ffffff !important;
}

.footer__section .klaviyo-form-WJNnKp.klaviyo-form input.needsclick.go610784064.kl-private-reset-css-Xuajs1 {
    border: 1px solid #e8e8e1 !important;
}

.klaviyo-form input::placeholder {
    color: #000000 !important;
    opacity: 1;
}

.color-scheme-2 .kl-private-quill-wrapper-Lkqws1.kl-private-quill-wrapper-Lkqws1.kl-private-quill-wrapper-Lkqws1 .ql-editor strong {
    color: #ffffff !important;
}

.color-scheme-2 .kl-private-quill-wrapper-Lkqws1.kl-private-quill-wrapper-Lkqws1.kl-private-quill-wrapper-Lkqws1 .ql-editor.ql-editor.ql-editor {
    color: #ffffff !important;
}

.color-scheme-2 .kl-private-quill-wrapper-Lkqws1.kl-private-quill-wrapper-Lkqws1.kl-private-quill-wrapper-Lkqws1 .ql-font-nunito-sans {
    color: #ffffff !important;
}
[data-testid="POPUP"] form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:first-child {
    display: inline-block !important;
    margin: 0 !important;
      margin-bottom: -4px !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 {
    display: inline-block !important;
    padding: 20px !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(2) {
    display: inline-block !important;
    width: 43% !important;
    padding: 0 !important;
    vertical-align: top;
    padding-left: 9% !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(2) [data-testid="form-row"]:nth-child(4) {
    display: inline-block !important;
    width: 30%;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(2) [data-testid="form-row"]:nth-child(3) {
    display: inline-block !important;
    width: 70%;
    vertical-align: top !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(2) [data-testid="form-row"]:nth-child(4) [component="[object Object]"] {
    display: block !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(2) [data-testid="form-row"]:nth-child(4) [component="[object Object]"] button {
    width: 100% !important;
    border-radius: 0px 4px 4px 0px !important;
    margin-left: -10px;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(2) [data-testid="form-row"]:nth-child(3) input {
    border: 1px solid #CFCFCF !important;
    padding: 10px 30px !important;
    height: 40px !important;
}
[role="dialog"] {
    width: 80%;
}
.collapsibles--product-description.rte ul {
    padding-left: 0 !important;
    list-style: none !important;
    margin-left: 0 !important;
}
.st_msg {
    display: none;
}

.loox-rating {
    display: none;
}

.grid-product__title {
    margin-bottom: 20px;
}
.length_cover label {
    color: #000000 !important;
}
.collapsibles--product-description.rte ul li {
    padding-left: 45px;
    background-repeat: no-repeat !IMPORTANT;
    background-position: top 6px left !important;
}
ul.site-nav.site-navigation.site-navigation--below.small--hide {
    display: inline-flex;

}
.product-single__meta .loox-rating {
    display: none !important;
}
.site-nav__link {
    white-space: inherit;
    text-align: center !important;
}
.product-single__form-price label.variant__label {
    display: none !important;
}

.variant-wrapper--dropdown {
    width: 100%;
}

.variant-wrapper--dropdown select {
    padding: 10px 20px;
    width: 100% !important;
    border-radius: 5px !important;
}
.header-item--search img.mbdis {
    display: none !important;
}

.site-header__search-form {
    width: 70%;
    margin: auto !important;
    border-radius: 5px;
    border-width: 2px;
}

.header-item.header-item--search.small--hide {
    text-align: center;
}

body .site-header__search-input {
    border-radius: 5px !important;
}

h1.h2.product-single__title {font-weight: 600;font-size: 24px;}

.product-single__meta {
    padding-top: 40px !important;
}

button.btn.btn--full.add-to-cart {
    background: #4CC298 !important;
    border-radius: 4px !important;
  margin-left: 20px;
}

button.btn.btn--full.add-to-cart:hover {
    background: #2BA277 !important;
    transition: all 0.3s;
}
div#shopify-section-client-logo h2.section-header__title {
    border-bottom: 1px solid rgb(128 128 128 / 30%);
    padding-bottom: 15px !important;
    font-weight: 600 !important;
    font-family: "Nunito Sans";
    font-size: 22px;
}
.pro-dec-arrow {
    font-size: 14px;
    margin-top: -5px;
}

.product-single__header {
    margin-bottom: 20px !important;
}


.product-dec {
    margin-bottom: 30px !important;
    font-size: 22px !important;
}
.sales_point {
    border-bottom: 1px solid rgb(128 128 128 / 30%);
    padding: 15px 0;
    margin: 0 !important;
}

.sales_point .icon {
    width: 36px !important;
    height: 36px !important;
    margin-left: 20px;
    margin-right: 20px;
}

ul.sales_points {
    border-top: 1px solid rgb(128 128 128 / 30%);
    margin-top: 10px;
}

.sales_point:last-child {
    display: none !important;
}
.js-qty__num {
    padding: 13px 0;
    border-radius: 5px;
}
.product__quantity--dropdown {
    width: 100%;
    display: flex;
}


.loox-rating {
    margin-bottom: 20px;
}

span.exemptify_inc_vat {
    color: #000000 !important;
    font-size: 30px;
    font-weight: 600 !important;
    font-family: 'Nunito Sans', sans-serif;
}

span.exemptify_inc_vat span, span.exemptify_ex_vat span {
    font-size: 19px !important;
  font-weight:400;
}

span.exemptify_ex_vat {
    color: #808080 !important;
}

.footer__block {
    flex: 0 1 25%;
    max-width: 33%;
    padding: 0 10px;
}
.length_cover label{
	color: #ff0000;
}
    
.footer-promotion {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-promotion .grid__image-ratio--wide:before {
    padding-bottom: 0;
}
.slick-slide {
    cursor: grab;
}
.footer-promotion .grid__image-ratio img {
    max-width: 50px;
    position:relative;
    margin: 0 auto;
    margin-right:10px;
}

.header-item--search {
    flex: 1 1 100%;
    max-width: 100%;
}


.footer-promotions [data-view="4-1"] .grid-item {
    flex: 0 0 25%;
}
.megamenu__col-title {
    display: none;
}
#looxReviews{
	    max-width: inherit;
}
.grid-product__price span, .cart__price span {
    font-size: 14px !important;
    font-weight: normal;
    
}

.grid-item__meta {
    margin: 20px 10px;
}


.cart_btn{
	margin-bottom: 10px;
}


.top-bar-item p, .top-bar-item a {
    color: #ffffff !important;
    margin: 0;
}

a.download_pdf {
  width: 100%;
  display: flex;
  align-items: center;
}
.download_pdf span {
  margin-right: 10px;
}
.download_pdf img {
  height: 50px;
  margin: 0;
  margin-top: 5px;
}


.inner-logos .slick-slide img {
    display: block;
    max-height: 150px;
    margin: 0 auto;
    align-items: center;
}

.inner-logos .slick-prev, .inner-logos .slick-next{width:40px; height:40px;}

.inner-logos .slick-slide {
    cursor: grab;
    align-items: center;
    display: flex!important;
    min-height: 150px;
    background: #fff;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
}

.template-collection #shopify-section-client-logo {
  /*  padding-top: 30px;*/
    margin-bottom: 20px;
}


.inner-logos .slick-prev {
    left: 0;
    z-index:9;
}
.inner-logos .slick-next {
    right: 0;
  z-index:9;
}


.inner-logos .slick-prev:before, .inner-logos .slick-next:before{display:none;}

.site-nav__item a {
    font-weight: bold;
}

#HeaderWrapper li.slide-nav__item span.slide-nav__link, .slide-nav__dropdown .slide-nav__item .slide-nav__link span {
    font-weight: bold;
    font-size: 16px;
}

.st_msg{
	color: #008a00;
  	position: relative;
  	padding-left: 14px;
}
.st_msg::before {
    position: absolute;
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    left: 0;
    top: 8px;
    border-radius: 100%;
    background: #008a00;
}
img.mbdis {
    display: none;
}


@media (max-width: 1300px) {
.site-nav__link {
    padding: 16px 15px;
    font-size: 14px !important;
}
      svg.icon.icon-hamburger {
    color: #0f4c89;
}

}

@media (max-width: 1024px) {
  button#hideshow {
    display:block;
}
.site-header__search-form {
    width: 100% !important;
    border-radius: 0 !important;
    border: 0 !important;
}
.hero__sidebyside-content.new-slideshow {
    max-width: 80%;
}
.site-header__element--sub[data-type=search] .page-width {
    padding-top: 10px;
    padding-bottom: 11px;
}
  .header-wrapper--compressed div#content-search.show {
    top: 135px;
}
}

@media only screen and (max-width: 768px) {
.product-single__form-price {
    margin-bottom: 20px;
}
  .header-wrapper--compressed div#content-search.show {
    top: 125px;
}
.new-footer-pro.footer-promotions [data-view="4-1"] .grid-item {
    flex: 0 0 100%;
}
  .small--text-center .hero__link .btn {
    width: 43% !important;
    min-width: auto;
    display: inline-block;
    font-size: 11px !important;
}
.header-wrapper:not(.header-wrapper--compressed) [data-nav=below] .js-search-header {
    content: url(https://cdn.shopify.com/s/files/1/0585/9986/6575/files/search.svg?v=1661838581);
    display: block !important;
}

#mobile-optimized-container.fs-ac-46045w {
    top: 50px !important;
}

.header-section {
    padding-bottom: 0;
}

.hero__sidebyside-content.new-slideshow h2.h1.hero__title {
    margin-bottom: 20px !important;
}
.js-qty__num {
    padding: 9px 0;
}
  #shopify-section-footer-promotions .new-grid{
    display: block;
  }
  .footer-promotion {
    padding: 20px 10px;
    display: flex;
    align-items: center;
  }
  .footer__grid-image {
    display: block;
    text-align: center;
    margin-bottom: 17px;
    margin-right: 20px;
    margin-left: 20px;
}
  ul.footer__menu.panel {
    margin-bottom: 10px;
  }
  .footer__blocks{
    display: block;
    width: 100%;
  }
  .footer__block {
    flex: inherit;
    max-width: 100%;
    padding: 0 10px;
  }
  .footer__mobile-section{
  	border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
  .footer__blocks--mobile{
  	display: block;
  }
  
  .accordion {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: 0;
    transition: .4s;
}

/*   .active{
    background-color: #ccc; 
  } */

  .panel {
    
    display: none;
    
  }
  .accordion:after {
    content: '+'; /* Unicode character for "plus" sign (+) */
    font-size: 16px;
    float: right;
    margin-left: 5px;
  }

  .active:after {
    content: "-"; /* Unicode character for "minus" sign (-) */
  }
  #AnnouncementSlider .flickity-viewport {
    height: 22px !important;
    margin-top: 5px;
}
  .st_msg::before {
    top: 8px;
  }
}

@media (max-width: 980px) {
  
  [role="dialog"] {
    width: 80%;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:first-child {
    width: 100% !important;
    min-height: auto !important;
  padding: 0px !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 {
    display: inline-block !important;
    padding: 20px !important;
}

[component="[object Object]"] {
    padding-top: 0 !important;
    padding-bottom: 0 !im;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(1) [data-testid="form-row"]:nth-child(4) {
    display: inline-block !important;
    width: 30%;
}
form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 {
    padding: 40px !important;
}
  form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1>div:nth-child(1) [data-testid=form-row]:nth-child(1) strong.ql-font-nunito {
    font-size: 30px !important;
}
form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(1) [data-testid="form-row"]:nth-child(3) {
    display: inline-block !important;
    width: 70%;
    vertical-align: top !important;
}
form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(1) [data-testid="form-row"]:nth-child(4) [component="[object Object]"] {
    display: block !important;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(1) [data-testid="form-row"]:nth-child(4) [component="[object Object]"] button {
    width: 95% !important;
    border-radius: 0px 4px 4px 0px !important;
    margin-left: -10px;
}

form.needsclick.klaviyo-form.kl-private-reset-css-Xuajs1 > div:nth-child(1) [data-testid="form-row"]:nth-child(3) input {
    border: 1px solid #CFCFCF !important;
    padding: 10px 30px !important;
    height: 40px !important;
}

}


@media (max-width: 450px) {
  
  [role="dialog"] {
    width: 100% !important;
}
  .hero__sidebyside-content.new-slideshow {
    max-width: 100%;
}
}

span.product__price-wrap-6766095794383 {
    float: left !important;
}
span#ProductPrice-6766131577039 {
    color: red;
}

